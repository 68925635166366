import { AuthenticateUserResponse } from '@acrelec-cloud/apico-sdk';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import store from 'store';

import {AuthFacebook} from 'src/components/Auth/Login/External/AuthFacebook';
import {AuthGoogle} from 'src/components/Auth/Login/External/AuthGoogle';
import { loginDataConfig } from 'src/components/Auth/Login/LoginData.config';
import { FormComponent } from 'src/components/Form/FormComponent';
import { useTranslate } from 'src/components/Languages/translate.hook';
import { useStore } from 'src/contexts/store.context';

interface LoginComponentProps {
	onError?: (errorStatus: boolean) => void;
}

export const LoginComponent = observer(({onError}: LoginComponentProps) => {
	const history = useHistory();
	const {translate} = useTranslate();
	const [loginError, setLoginError] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		auth: {loginUser},
		order: {fetchCurrentOrders, emptyOrders},
	} = useStore();

	const submit = (data: {email: string; password: string}) => {
		setIsLoading(true);
		const previousUrl = store.get('previousUrl');
		loginUser(data.email, data.password)
			.then((user: AuthenticateUserResponse) => {
				if (user === undefined) {
					throw new Error();
				}
				if (previousUrl)return history.push(previousUrl);
				else return authSuccess(); // Redirect the user to the order page and fetch its orders
			})
			.catch(() => {
				emptyOrders();
				setIsLoading(false);
				setLoginError('Form.loginForm.error');
			});
	};

	useEffect(() => {
		return () => emptyOrders();
	});

  const authFailed = () => {
    emptyOrders();
    setIsLoading(false);
    setLoginError('Form.loginForm.error');
  }

  const authSuccess = () => {
    history.push('/order'); // Redirect the user to the order page and fetch its orders
    fetchCurrentOrders(20, 0, process.env.REACT_APP_CURRENT_ORDER_FILTERS, undefined);
  }

	const removeLoginError = (): void => {
		setLoginError('');
	};

	return (
		<div className="profile">
			<p className="profile__title txt-h1">{translate(`Profile.title.login`)}</p>

			<FormComponent
				classNameForm={'form'}
				classNameField={'form__field'}
				classNameSubmitButton={'form__btn btn__primary m-top__disabled'}
				classNameInput={'form__input txt-m auth__input'}
				inputsConfig={loginDataConfig}
				onSubmit={submit}
				callbackError={loginError}
				onChange={removeLoginError}
				isLoading={isLoading}
			/>

			<NavLink className="profile__reset txt-xs" to="/profile/forgot-password/request">
				{translate(`Profile.reset_password`)}
			</NavLink>

			<div className="profile__links">
				<p className="profile__account txt-l">
					{translate(`Profile.no_account`)}
					<Link to="/profile/create" className="profile__account__login txt-l">
						{translate(`Profile.signup`)}
					</Link>
				</p>
				<div className="auth__sso__methods">
					<b>{translate(`LoginPage.or`)}</b>
					<AuthGoogle onAuthFailed={() => authFailed()} onAuthSuccess={() => authSuccess()} />
					<AuthFacebook onAuthFailed={() => authFailed()} onAuthSuccess={() => authSuccess()} />
				</div>
				<p className="profile__guest txt-l">
					<span className="txt-l">{translate(`Profile.guest`)}</span>
					<Link to="/order" className="profile__guest__login txt-l">
						{translate(`Profile.continue_order_guest`)}
					</Link>
				</p>
			</div>
		</div>
	);
});
