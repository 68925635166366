export const fr = {
  App: {
    all: "Tout",
    calories: "CAL",
    add_cart_btn: "Ajouter au Panier",
    edit_cart_btn: "Mettre à jour",
    unavailable: "Indisponible",
    messages: {
      qty_max: "Vous avez atteint la quantité MAX.",
      qty_max_parent: "Vous avez choisi les options MAX.",
      qty_min: "Vous avez atteint la quantité MIN.",
    },
    no: "Non",
    yes: "Oui",
    day: {
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
    },
  },
  Basket: {
    checkout: "Valider",
    basket_empty: "Votre panier est vide",
    discount_message: "Prix appliquant la remise : ",
    order_summary: "Commande",
    quantity: "Quantité",
    recap_text:
      "* Le ticket de caisse détaillé vous sera remis avec la commande au restaurant.",
    show_details: "Détails",
    customize: "Modifier",
    subtotal: "Total HT",
    taxes: "Taxes",
    total: "Total avant discount",
    total_discounted: "Prix total",
    // total: "Total TTC",
  },
  BasketUpdatedModal: {
    message:
      "Certains produits de votre commande ne sont plus disponibles, ou leur prix a changé et ont été supprimés du panier :",
    button: "Fermer",
  },
  CancelOrderModalComponent: {
    success_text: "Votre commande à bien été annulée !",
    fail_text: "Votre commande n'a pas pu être annulée.",
  },
  ConfirmSentModal: {
    success: {
      title: "Votre message a bien été envoyé !",
      text_problem:
        "Merci beaucoup, le restaurant prendra bientôt contact avec vous !",
      text: "Merci beaucoup et à bientôt ",
    },
    error: {
      title: "Oups, quelque chose s'est mal passé !",
      text: "Veuillez retenter ultérieurement",
    },
    button: "Fermer",
  },
  CheckinOrderModal: {
    text: "Êtes-vous sûr de vouloir envoyer votre commande maintenant ?",
  },
  CancelOrderPage: {
    title: "Annulation de commande",
    text: "Veuillez vérifier les informations relatives à votre commande avant de procéder à l'annulation. Cette action est irréversible.",
    button: {
      cancel: "Annuler la commande",
      newOrder: "Nouvelle Commande",
    },
    error: "Désolé, vous ne pouvez plus annuler cette commande.",
    done: "Cette commande a été annulée.",
  },
  ModifyOrderPickupPage: {
    title: "Modification de l'heure de retrait",
    error: "Désolé, vous ne pouvez plus modifier cette commande.",
    button: {
      newOrder: "Nouvelle Commande",
    },
    already_injected:
      "Vous ne pouvez plus modifier votre commande car elle a déjà été envoyée.",
  },
  CheckoutPage: {
    discount: {
      title: "AJOUTER UNE RÉDUCTION",
      button: "Ajouter",
      error: "Coupon non valide",
      label: "Numéro de remise",
    },
    email: {
      label: "Email",
      placeholder: "jane.doe@example.com",
    },
    firstname: {
      label: "Prénom",
      placeholder: "Jane",
    },
    lastname: {
      label: "Nom",
      placeholder: "Doe",
    },
    phone_number: {
      label: "Téléphone",
      placeholder: "06XXXXXXXX",
    },
    pay: "Commander",
    proceed_pay: "Procéder au paiement",
    payment_method_title: {
      user: "PROCÉDER AU PAIEMENT",
      guest: "RENSEIGNEZ VOS INFORMATIONS",
    },
    phone: "Mobile",
    title: "Commande en cours",
    error: {
      name: "Votre nom doit être composé uniquement de lettre",
      phone:
        "Votre numéro de téléphone doit être composé uniquement de chiffres, + or ( )",
    },
    legal_message: {
      guest: 'En cliquant sur "Procéder au paiement", vous acceptez nos',
      user: 'En cliquant sur "Commander", vous acceptez nos',
    },
    offers_message:
      "J'accepte de recevoir par email les offres et actualités de Carl's Jr",
    protection_policy_message:
      "J'ai lu et compris la politique de protection des données personnelles de Carl's Jr",
    terms_of_use: "conditions générales",
    restaurant_closed: "Désolé, le restaurant est désormais fermé.",
  },
  CheckoutPageRecap: {
    title: "Récapitulatif de la commande",
  },
  ConfirmButton: {
    btn_next: "Commander",
  },
  ContactPage: {
    contact_us: "Contactez-nous ",
    link_text: "ici",
    text: "Vous avez une question, une suggestion, un problème ?",
    title: "Contact",
  },
  CurrentOrderPage: {
    back: "retour à la page produits",
    no_orders: "Aucune commande en cours",
    error: {
      INJECTION_ERROR:
        "Un problème s'est produit, votre commande n'a pas pu être envoyée. Contactez-nous ou rendez-vous au comptoir.",
      PAYMENT_ERROR:
        "Un problème s'est produit lors du paiement. Veuillez vous rendre au comptoir avec votre numéro de commande.",
      CANCELLED:
        "Votre commande a été annulée. Vous ne serez pas débité du montant de la commande.",
      CONFLICTED:
        "Certains produits internes à votre commande ont été modifiés par le restaurant.",
      unknown_error:
        "Un problème s'est produit. Contactez-nous ou rendez-vous au comptoir.",
    },
    order_restaurant_info: {
      will_be_send: "Votre commande va être envoyée à: ",
      has_been_sent: "Votre commande a été envoyée à: ",
      in_progress: "Envoi en cours ... ",
    },
    title: "Commandes en cours",
    conflict: {
      confirm: "Valider la commande",
    },
    order_again: "Commander à nouveau",
    unavailable: "Indisponible",
  },
  CurrentRestaurant: {
    btn_change: "Modifier",
    btn_choose: "Choisissez un restaurant",
    btn_details: "Horaires et informations",
    no_restaurant: "Vous n'avez pas encore sélectionné de restaurant.",
  },
  DeleteItemModalComponent: {
    text: "Êtes-vous sûr(e) de vouloir supprimer ce produit ?",
  },
  DeleteOrderModal: {
    text: "Êtes-vous sûr(e) de vouloir annuler votre commande ?",
    fail_text: "Désolé, vous n'êtes plus autorisé à annuler cette commande.",
    fail_title: "L'annulation de votre commande a échoué !",
    success_text: "Votre commande a bien été annulée.",
    success_title: "Annulation validée !",
    button: "Retour aux commandes",
  },
  DeletePaymentMethodModal: {
    text: "Êtes-vous sûr(e) de vouloir supprimer cette méthode de paiement ?",
  },
  DeleteUserAccountModal: {
    text: "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible",
  },
  DetailedFeedbackPage: {
    title: "Avis detaille",
    email: "Email",
    message: "Message",
  },
  DiscountTitle: "Code de réduction",
  DifferentRestaurantModal: {
    title: "Votre commande n'est pas disponible dans ce restaurant",
    text: "Voulez vous changer de restaurant et supprimer votre panier actuel ?",
  },
  DifferentPriceModal: {
    title: "Le prix de votre commande a changé",
    text: "Souhaitez-vous quand même l'ajouter au panier ?",
    previous_price: "Ancien prix",
    new_price: "Nouveau prix",
    validate: "Ajouter ma commande",
    cancel: "Non merci",
  },
  Footer: {
    contact: "Contact",
    legalsInformations: "Conditions générales",
    copyright_text: "Copyright Carl's Jr 2021 powered by ",
    copyright_link: "ACRELEC",
  },
  ForgotPassword: {
    title: {
      require: "Réinitialisation du mot de passe",
      sent: "Si vous avez un compte chez nous, vous recevrez un lien pour réinitialiser votre mot de passe par mail dans quelques minutes.",
      success: "LE MOT DE PASSE A ÉTÉ CHANGÉ AVEC SUCCÈS",
      fail: "OOPS ! QUELQUE CHOSE A MAL TOURNÉ",
    },
    link: {
      sent: "Je n'ai pas reçu d'email",
      success: "Aller à la connexion",
      fail: "Réessayer",
    },
    reset: {
      title: "Réinitialisation du mot de passe",
      description:
        "Remplissez le formulaire pour créer un nouveau mot de passe pour votre compte.",
      button: "Envoyer",
    },
  },
  Form: {
    radio_button: "Payer avec cette carte ",
    editProfileForm: {
      phone_number: "Téléphone",
      first_name: "Prénom",
      last_name: "Nom",
      email: "Email",
      password: "Mot de passe",
      confirm_password: "Confirmation du mot de passe",
      placeholder: {
        email: "Email",
        first_name: "Clark",
        last_name: "Kent",
        password: "Mot de passe",
        confirm_password: "Confirmation du mot de passe",
      },
    },
    errors: {
      required: "Champ requis",
      required_login: {
        email: "Email requis",
        password: "Mot de passe requis",
      },
      no_message: "",
      pattern: {
        password:
          "Doit contenir une majuscule, une minuscule, un chiffre et au minimum 8 caractères",
        email: "Email non valide",
        firstName:
          "Le prénom doit contenir seulement des lettres, espaces et tirets",
        lastName:
          "Le nom doit contenir seulement des lettres, espaces et tirets",
        number: "Ne doit contenir que des chiffres",
        phone: "Numéro de téléphone non valide",
      },
      minLength: {
        password:
          "Doit contenir une majuscule, une minuscule, un chiffre et au minimum 8 caractères",
      },
      maxLength: {
        password: "Doit contenir 30 caractères maximum",
      },
      validate: {
        confirm_password: "Les mots de passe doivent être les même",
      },
    },
    loginForm: {
      old_password: "Ancien mot de passe",
      new_password: "Nouveau mot de passe",
      new_password_confirm: "Confirmation nouveau mot de passe",
      email: "Email",
      placeholder: {
        email: "Email",
        password: "Password",
      },
      error: "Email ou mot de passe incorrect",
    },
    payment_method: {
      checkbox:
        "Souhaitez-vous sauvegarder votre moyen de paiement pour un usage ultérieur ?",
    },
    prefix: {
      new: "Nouveau",
    },
    reset: "Effacer",
    resetPasswordForm: {
      reset: "Réinitialisation du mot de passe",
      reset_email:
        "Un email va vous être envoyé afin de réinitialiser le mot de passe",
    },
    submit: "Envoyer",
  },
  IncompatiblePriceModal: {
    title: "Le paiement a échoué !",
    content:
      "Il y a eu un changement sur le prix total. Veuillez contacter le support.",
    button: {
      continue: "Continuer avec le nouveau prix",
      cancel: "Annuler la commande",
    },
    error:
      "PRIX INCOMPATIBLE : Il y a eu un changement sur le prix total. Veuillez contacter notre support.",
  },
  HomeMenuPage: {
    title: "Page d'accueil",
    button: "Commander",
    welcome: "Bienvenue !",
  },
  LegalsPage: {
    title: "Conditions générales",
  },
  LoginPage: {
    title: "Connexion",
    or: "ou",
  },
  MenuComponent: {
    allergens: "allergènes",
    history: "Historique de commandes",
    home: "Accueil",
    restaurant: "Restaurant",
    restaurants: "Restaurants",
    theme_styles: "Styles de thème",
    order: "Commander",
    language: "Langue",
    profile: "Profil",
  },
  Navbar: {
    basket: "Panier",
    menu: "Menu",
    payment: "Paiement",
    timer: "Commande en attente",
    store: "Restaurant Actuel",
  },
  NoAvailableProductsModal: {
    text: "Malheureusement, tous les produits sont indisponibles.",
    cancel: "fermer",
    confirm: "Continuer mes achats",
  },
  OrderHistoryPage: {
    no_order: "Votre historique de commande est vide.",
    title: "Historique des commandes",
  },
  OrderContainer: {
    number: "Commande numéro : ",
    time_estimated: "Heure de récupération de la commande :",
    cancel_order: "Annuler la commande",
    confirm: "Confirmer",
    change_pickup: "Changer l'heure de retrait",
    feedback_sent: "Avis détaillé envoyé!",
    update_order_first: "Je suis déjà au restaurant, ",
    update_order_second: " maintenant",
    update_order_button: "envoyer ma commande",
  },
  OrderDisabledModal: {
    basket_not_empty:
      "Voulez-vous supprimer votre panier et changer de restaurant ?",
    message: "Les commandes ont été désactivées par le restaurant.",
    show_menu: "Vous pouvez cependant voir le menu.",
    change: "Changer de restaurant",
    continue: "Continuer",
    cancel: "Annuler",
  },
  ProductUnavailable: {
    title: "Produit(s) indisponible(s)",
    buttons: {
      replace: "Remplacer le(s) produit(s) manquant(s",
      delete: "Supprimer ma commande",
      continue: "Continuer sans le(s) produit(s) manquant(s)",
    },
  },
  OrderEnable: {
    title: "Les commandes sont activées",
    content:
      "Maintenant, vous pouvez ajouter des produits au panier et passer des commandes.",
    button: "Continuer",
  },
  OrderPage: {
    error: "Erreur",
    title: "Page de commande",
    not_found_first_message: "Cette page n'existe pas ou n'existe plus.",
    not_found_second_message:
      "Nous vous prions de nous excuser pour la gêne occasionnée.",
    redirect_message: "Nous vous invitons à sélectionner ",
    not_found_button: {
      order: "d'autres produits",
      store: "un autre restaurant",
    },
    see_details: "Détails de la commande",
    hide_details: "Cacher les détails",
    reorder: "Commander à nouveau",
  },
  OrderRating: {
    message: "Dites-nous ce que vous avez pensé:",
    success: "Merci pour votre appréciation !",
    signal: "Signaler un problème",
    report_sent: "Problème signalé !",
  },
  OrderRatingModal: {
    success: {
      title: "Merci d'avoir noté votre expérience !",
      text: "Voulez-vous nous en dire plus en nous laissant un avis détaillé?",
    },
    error: {
      title: "Oups, quelque chose s'est mal passé !",
      text: "Veuillez retenter ultérieurement",
    },
    no: "Non merci",
    yes: "Oui !",
  },
  ProductCustomizationModal: {
    title: "Personnalisez vos ingrédients",
    confirm: "Confirmer",
  },
  Pagination: {
    textOne: "Commandes: ",
    textTwo: " sur ",
    button: "Afficher Plus",
  },
  Parts: {
    parts: "Personnalisation",
    reset: "Réinitialisation",
    part_title: {
      option: {
        show: "Choisir une option",
        hide: "Choisir une option",
      },
      regular: {
        show: "Personnaliser",
        hide: "Personnaliser",
      },
    },
    group_title: {
      included: "Ingrédients Inclus",
      extra: "Boostez Votre Repas",
      required: "Options Requises",
    },
  },
  PaymentCancel: {
    title: "Paiement annulé",
    message: "La transaction a été annulée par le client.",
    button: "Retourner à la page de paiement",
  },
  PaymentFailedModal: {
    title: "Le paiement a échoué!",
    content: "Veuillez réessayer",
  },
  PaymentMethod: {
    validity: "Date d'expiration : ",
    changeCheckbox: "Ajouter une nouvelle carte",
  },
  PaymentRefused: {
    title: "Paiement refusé",
    message: "Veuillez réessayer.",
    button: "Retourner à la page de paiement",
  },
  PaymentSuccessModal: {
    title: "La commande a été effectuée!",
    content: "Votre numéro de commande est le",
    button: "Suivre votre commande",
  },
  PickRestaurantButton: {
    btn_choose: "Choisir",
    btn_active: "Actif",
  },
  ProductChoice: {
    item: "Produit",
    meal: "Menu",
  },
  ProductPage: {
    title: "Page Produit",
    search: "Rechercher un produit",
    addToCartButton: {
      RESTAURANT_CLOSED: "Désolé, le restaurant est fermé.",
      ORDERS_UNAVAILABLE:
        "Désolé, les commandes sont temporairement indisponibles.",
      REQUIRED_PARTS: "Désolé, certaines options sont requises.",
    },
  },
  Profile: {
    account: "Vous avez déjà un compte?",
    confirm: "Confirmer",
    error: "Erreur",
    first_name: "Prénom",
    form: {
      login: "Se connecter",
    },
    input: {
      password: "Mot de passe",
      password_confirm: "Confirmez le mot de passe",
    },
    last_name: "Nom",
    link: {
      email: "Je n'ai pas reçu d'email",
      guest: "Continue en tant qu'invité",
    },
    login: "Se connecter",
    logout: "Se déconnecter",
    no_account: "Vous n'avez pas de compte?",
    sso_signup: {
      google: "Se connecter avec Google",
      facebook: "Se connecter avec Facebook",
    },
    guest: "Vous ne souhaitez pas créer de compte?",
    paragraph: {
      email:
        "Un email de confirmation va être envoyé pour confirmer la création de votre compte",
      email_box: "S'il vous plait regardez vos emails",
    },
    reset_password: "Réinitialisation du mot de passe",
    signup: "S'inscrire",
    continue_order_guest: "Continuer en tant qu'invité",
    modal: {
      success: {
        title: "Compte créé",
        content: "Choisissez un restaurant et commencez à commander!",
        button: "Choisissez un restaurant",
      },
    },
    title: {
      account: "Confirmation de compte",
      account_update: "Modifiez votre profil",
      email: "S'il vous plait, entrez votre email",
      email_sent: "L'email a été bien envoyé.",
      login: "Se connecter à mon compte",
      problem: "Un problème est survenu",
      profile_page: "GÉRER VOTRE PROFIL",
      update: "Modifiez votre profil",
    },
    try_again: "S'il vous plait, veuillez réessayer",
    already_exist: "Cette adresse e-mail est déjà associée à un autre compte",
  },
  ProfileConfirmationPage: {
    title: "Création profil",
  },
  ProfilePage: {
    btn_confirm: "Confirmer",
    check_password: "Confirmation du nouveau mot de passe:",
    delete_account: "Supprimer mon compte",
    edit_profile: "Modifier",
    email: "Email",
    first_name: "Prénom",
    last_name: "Nom",
    new_password: "Nouveau mot de passe:",
    old_password: "Ancien mot de passe:",
    payment_method_title: "GÉRER VOS MOYENS DE PAIEMENTS",
    reset_password: "Réinitialisation du mot de passe",
    title: "Profil",
    phone_number: "Téléphone",
  },
  ProfileCreationPage: {
    title: "Inscription",
    legal_message: "En cliquant sur “Confirmer”, vous acceptez nos",
    terms_of_use: "conditions générales",
  },
  ReorderSuccessModal: {
    title: "Votre commande a bien été ajouté à votre panier",
    text: "Merci beaucoup, et à très bientôt !",
    open_basket: "Ouvrir mon panier",
    continue: "Continuer mes achats",
  },
  ResetBasketModalComponent: {
    text: "Les produits peuvent différer selon le restaurant selectionné. Voulez-vous supprimer votre panier et changer de restaurant ?",
  },
  RestaurantItem: {
    address: "Adresse",
    closed: "Fermé",
    hours: "Horaires",
    infos: "Infos",
    open: "Ouvert",
    ordering_disabled: "⚠ Commandes en ligne temporairement suspendues",
  },
  RestaurantsPage: {
    closed: "Fermé",
    from: "de",
    title: "Restaurant",
    to: "à",
    map: {
      loading: "Chargement de la carte en cours...",
    },
    search: {
      placeholder: "Recherche par code postal",
    },
    noRestaurant: "Désolé, il n'y a pas de restaurants à cet endroit.",
  },
  RestaurantAuthenticationPage: {
    authentication_code: "Code d'authentification",
    button: "Confirmer",
    error_message: "Veuillez réessayer",
    redirection_message: "Retourner sur ",
    title: "Veuillez entrer le code",
  },
  SelectHourComponent: {
    title: "CHOISIR VOTRE HEURE DE RETRAIT",
    newTitle: "CHOISIR VOTRE NOUVELLE HEURE DE RETRAIT",
    subTitle: "Horaires valables pour le retrait :",
    address: "Adresse du restaurant : ",
  },
  StripeError: {
    "billing_details[name]": "Nom invalide",
    "billing_details[phone]": "Numéro de téléphone invalide",
  },
  StudySurveyFormPage: {
    title: "Signaler un probleme",
    email: "Email",
    phone_number: "Téléphone",
    subject: "Objet",
    message: "Message",
    agree: {
      sentence:
        "En soumettant ce formulaire, j’accepte que mes informations soient utilisées exclusivement dans le cadre de ma demande et de la relation commerciale éthique et personnalisée qui pourrait en découler.",
    },
    button: "Envoyer",
    name: "Nom",
    recaptcha: {
      policy: "La politique de confidentialité",
      terms: "les conditions d'utilisation",
      sentence: {
        main: "Ce site est protégé par reCAPTCHA et Google.",
        and: "et",
        apply: "s'appliquent",
      },
    },
  },
  TechnicalIssuePage: {
    title: "Oups, quelque chose s'est mal passé.",
    redirect: "page de sélection de restaurants",
    message_firstPart: "Nous vous invitons à revenir vers la ",
    message_secondPart: " de notre site.",
    button: "contacter le restaurant",
  },
  UnavailableProductModal: {
    title: "Un article de votre commande n'est pas disponible actuellement",
    text: "Souhaitez vous quand même ajouter cette commande au panier ?",
    left_button: "Non merci",
    right_button: "Ajouter ma commande",
    missing_products: "Produit(s) manquant(s) : ",
  },
};
